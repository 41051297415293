<template>
  <g-card page-title="Link Insertion List" page-icon="mdi-link">
    <g-list-page
        :headers="headers"
        sort="id"
        stateendpoint="linkInsertion.linkInsertions"
        :show-default-action-buttons="false"
        :show-add-button="false"
        @loadData="loadData"
        @editItem="editItem"
        @deleteItem="deleteItem"
        @destroyItem="destroyItem"
        @restoreItem="restoreItem"
        @switchHandler="switchHandler"
    >
      <template v-slot:overlay>
        <page-overlay :overlay="isOverlay" />
      </template>
      <template v-slot:liveLink="{item}">
        {{item.liveLink}}
      </template>
      <template v-slot:top>
        <v-row>
          <v-col cols="12" md="6" sm="8">
            <g-p-search
                hint="Minimum two characters needed to search. Columns(site url, live link)"
                @applySearch="handleSearch"
            />
          </v-col>
        </v-row>
      </template>
    </g-list-page>

    <confirm-popup
        :show="showConfirm"
        confirm-event="onConfirmItem"
        @closePopupHandler="showConfirm = false"
        @onConfirmItem="confirmEvent(currentSelectedItem, true)"
    >
      <div>
        <!-- eslint-disable-next-line  -->
        <span>{{ confirmTxt }}</span>
      </div>
    </confirm-popup>
  </g-card>
</template>

<script>
    import GCard from "../components/GCard";
    import GListPage from "../components/list/GListPage";
    import PageOverlay from "../components/PageOverlay";
    import GPSearch from "../components/GPSearch";
    import ConfirmPopup from "../components/popups/ConfirmPopup";
    import ListMixins from "../components/mixins/list/List";
    export default {
        name: "LinkInsertions",
        metaInfo: {
            title: 'rabbiitfirm.com',
            titleTemplate: 'Admin Dashboard - Link Insertion List | %s',
            meta: [
                { name: 'robots', content: 'noindex' },
                { name: 'googlebot', content: 'noindex' }
            ]
        },
        components: {
          ConfirmPopup,
          GPSearch,
          PageOverlay,
          GListPage,
          GCard,
        },
        mixins: [ListMixins],
        data() {
            return {
                headers: [
                    {
                      text: 'Site URL',
                      value: 'site.site_url',
                      sortable: false
                    },
                    {
                      text: 'Live Link',
                      value: 'liveLink'
                    },
                    {
                      text: 'Is allowed link insertion?',
                      value: 'isActive',
                      cellClass: "center-switch",
                      align: "center"
                    }
                ],
              columns: [
                {
                  name: "site.site_url",
                  callback: "getMoneySign"
                },
              ],
                actions: {
                    load: 'linkInsertion/load',
                    update: 'linkInsertion/update'
                }
            }
        }
    }
</script>

<style scoped>

</style>